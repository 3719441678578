import {API} from '../constants';
import axios from 'axios';

export const getServiceOrders = async (filters) => {
    let url = `${API.SERVICE_ORDERS}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}

export const getServiceDashboardData = async () => {
    return axios.get(`${API.SERVICE_ORDERS}dashboard/`)
}

export const getDeliveredServiceOrders= async () => {
    return axios.get(`${API.SERVICE_ORDERS}delivered/`)
}


export const getOngoingServiceOrders = async () => {
    return axios.get(`${API.SERVICE_ORDERS}ongoing/`)
}

export const getAcceptedServiceOrders = async () => {
    return axios.get(`${API.SERVICE_ORDERS}accepted/`)
}

export const getRejectedServiceOrders = async () => {
    return axios.get(`${API.SERVICE_ORDERS}rejected/`)
}

export const getClosedServiceOrders = async () => {
    return axios.get(`${API.SERVICE_ORDERS}realized/`)
}

export const getNewServiceOrders = async () => {
    return axios.get(`${API.SERVICE_ORDERS}new/`)
}

export const updateServiceOrder = async (uuid, body) => {
    return axios.patch(`${API.SERVICE_ORDERS}${uuid}/`, body)
}

export const synchronizeServiceOrder = async (uuid) => {
    return axios.post(`${API.SERVICE_ORDERS}${uuid}/synchronize/`)
}

export const getNotes = async (filters) => {
    let url = `${API.NOTES}all/`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}

export const createNote = async (body) => {
    return axios.post(`${API.NOTES}`, body)
}

export const getStage = async (filters) => {
    let url = `${API.STAGES}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url)
}

export const getAllStages = async () => {
    return axios.get(`${API.STAGES}all/`)
}

export const getAttributes = async (filters) => {
    let url = `${API.ATTRIBUTES}all/`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url)
}

export const getActiveAttributesDefinition = async () => {
    return axios.get(`${API.ATTRIBUTES_DEFINITION}active/`)
}

export const getAttributesDefinitionItems = async (filters) => {
    let url = `${API.ATTRIBUTES_DEFINITION_ITEMS}all/`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url)
}

export const getServiceOrderActivities = async (filters) => {
    let url = `${API.ACTIVITIES}`
    if (filters) {
        Object.entries(filters).forEach(([k, v]) => {
            url = url + `?${k}=${v}`
        })
    }
    return axios.get(url);
}

export const createOrderActivity = async (data) => {
    return axios.post(`${API.ACTIVITIES}`, data);
}

export const updateOrderActivity = async (uuid, body) => {
    return axios.patch(`${API.ACTIVITIES}${uuid}/`, body)
}

export const updateStage = async (uuid, body) => {
    return axios.patch(`${API.STAGES}${uuid}/`, body)
}

export const getEmailSent = async () => {
    return axios.get(`${API.EMAILS_SENT}`)
}

export const getAllOrderTypes = async () => {
    return axios.get(`${API.ORDER_TYPES}all/`)
}

export const updateOrderType = async (uuid, body) => {
    return axios.patch(`${API.ORDER_TYPES}${uuid}/`, body)
}

export const downloadServicerOrderImages = async (uuid) => {
    axios({
        url: `${API.SERVICE_ORDERS}${uuid}/images/`,
        method: "GET",
        responseType: "blob",
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `zdjecia.zip`
        );
        document.body.appendChild(link);
        link.click();
    });
}

export const updateAttribute = async (uuid, body) => {
    return axios.patch(`${API.ATTRIBUTES}${uuid}/`, body)
}