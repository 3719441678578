import React, {useEffect, useState} from "react";
import {Grid, TextField} from '@material-ui/core';
import CustomDialogSave from "../common/CustomDialogSave";
import {OutlinedInput} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from "@mui/material/Box";
import {getActiveAttributesDefinition} from "../../services/serviceOrdersService";
import {getAllEmailTemplates} from "../../services/settingService";

export const EmailStageDialog = ({
                                 open,
                                 handleClose,
                                 stagesData,
                                 handleSave,
                                 emailNames,
                                 setEmailNames
                             }) => {
    const [emails, setEmails] = useState([]);

    const [emailsDict, setEmailsDict] = React.useState({});

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setEmailNames(
          typeof value === 'string' ? value.split(',') : value,
        );
      };

    const handleSaveInternal = () => {
        let uuid = [];
        emailNames.forEach((item)=> {
            uuid.push(emailsDict[item])
        })
        setEmailNames([]);
        handleSave(uuid);
    }

    const handleCloseInternal = () => {
        handleClose()
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const getAllAttributesLookup = async () => {
        const emailTemplates = await getAllEmailTemplates();
        console.log(emailTemplates);
        let emailTemplatesLookup = [];
        let emailTemplatesDictTemp = {};
        emailTemplates.data.forEach((item) => {
            emailTemplatesLookup.push(item);
            emailTemplatesDictTemp[item.name] = item.uuid
        });
        setEmailsDict(emailTemplatesDictTemp);
        return emailTemplatesLookup
    }

    useEffect(() => {
        getAllAttributesLookup().then(r => setEmails(r));
    }, []);

    return (
        <CustomDialogSave open={open} handleClose={handleCloseInternal} maxWidth={"sm"} title={'Etapy - wzory email'}
                          handleSave={handleSaveInternal}>
            <Grid item xs={12}>
                <TextField id="outlined-basic" label="Etap" variant="outlined" fullWidth
                           defaultValue={stagesData.description} disabled={true}/>
            </Grid>
            <Grid item xs={3}>
                Wzory emaili
            </Grid>
            <Grid item xs={9}>
                <Select
                    id="demo-multiple-chip"
                    multiple
                    style={{minWidth: "100%"}}
                    value={emailNames}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                    renderValue={(selected) => (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value}/>
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {emails.map((item) => (
                        <MenuItem
                            key={item.uuid}
                            value={item.name}
                        >
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>

        </CustomDialogSave>
    )
};